import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "../components/container"
import OuterContainer from "../components/outerContainer"
import Title from "../components/title"

export default function Home() {
  return (
    <OuterContainer>
      <Header />
      <Title text="Taogi Design"/>
      <Container>
        <p>Thoughtful software for iOS.</p>
      </Container>
      <Footer />
    </OuterContainer>
  );
}
